import React from 'react'
import { Link } from "gatsby"
import ScrollAnimation from 'react-animate-on-scroll';
import styles from './iconCard.module.scss';

class IconCard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const Inner = (
      <ScrollAnimation className={styles.card} animateIn="fadeInUp" animateOnce={true}>
        <ScrollAnimation animateIn="fadeIn" animateOnce={true} delay={250}>
          {this.props.icon}
          <p>{this.props.copy}</p>
        </ScrollAnimation>
      </ScrollAnimation>
    )

    return (
      this.props.link ?
      (
        <Link to={this.props.link}>
          {Inner}
        </Link>
      ) : Inner
    )
  }
}
export default IconCard