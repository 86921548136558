import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Heading from "../components/heading/heading";
import ScrollAnimation from 'react-animate-on-scroll';
import Img from "gatsby-image";

import IconCard from "../components/iconCard/iconCard";
import Retail from "../images/retail.inline.svg";
import Enterprise from "../images/enterprise.inline.svg";
import CStore from "../images/c-store.inline.svg";
import Restaurant from "../images/restaurant.inline.svg";

const IndustriesPage = ({ data }) => (
  <Layout>
    <SEO title="Industries" />
    <ScrollAnimation className="mt-5" animateIn="fadeInUp" animateOnce={true} style={{marginBottom: 100}}>
      <Heading
        title="Industries"
        copy="The RetailStack Commerce Platform offers configuration and support for a wide variety of industries including convenience stores, restaurants, dispensed fuel and other retailers who want to outmaneuver their competitors. Learn more about how a specific solution stack can benefit your industry."
        align="center"
      />
      <Img fluid={data.file.childImageSharp.fluid} alt="industries header" />
    </ScrollAnimation>
    <div className="row" style={{marginBottom: 150}}>
        <div className="col-sm-12 col-md-6 col-lg-3">
          <IconCard 
            icon={<Retail/>}
            copy="A single platform for traditional and digital commerce."
            link="/industries/retail"
          />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-3">
          <IconCard 
            icon={<CStore/>}
            copy="Innovations that fuel growth from the forecourt to the back office."
            link="/industries/c-store"
          />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-3">
          <IconCard 
            icon={<Restaurant/>}
            copy="Built in benefits for complete control over your entire business."
            link="/industries/restaurant"
          />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-3">
          <IconCard 
            icon={<Enterprise/>}
            copy="Above store management, rich reporting and more."
            link="/industries/enterprise"
          />
        </div>
      </div>
  </Layout>
)

export default IndustriesPage

export const query = graphql`
  query {
    file(relativePath: { eq: "industries-header.png" }) {
      childImageSharp {
        fluid(maxWidth: 1170) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`